<template>
  <div
    class="certificate-container d-flex align-items-center justify-content-center"
    :style="{position: 'relative'}"
  >
    <!-- 下載png時所使用的圖（srcset圖片無法下載png）-->
    <img :src="require(`@/assets/img/personal/certificate.png`)" />
    <div
      class="text-wrapper d-flex flex-column align-items-center justify-content-center position-absolute"
    >
      <span
        class="logo-title d-flex align-items-center w-100 justify-content-center"
      >
        {{ appName }}
      </span>
      <span
        class="stage-title font-weight-bold d-flex align-items-center w-100 justify-content-center"
      >
        {{ $t('{text}棋力檢定證書', {text: rankText}) }}
      </span>
      <span
        class="username font-weight-bold d-flex align-items-center w-100 justify-content-center"
      >
        {{ name }}
      </span>
      <div class="content d-flex flex-column align-items-center w-100">
        <span>{{ $t('參加棋力檢定測驗已達標準') }}</span>
        <span>{{ $t('特頒鑑定證書，以資證明') }} </span>
      </div>
      <span class="date d-flex align-items-center w-100 justify-content-center">
        {{ time }}
      </span>
    </div>
  </div>
</template>

<script>
import {appName} from '@/constant/env.js';
import ranksMapping from '@/json/rank.json';

export default {
  name: 'Certification',
  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },
    rank: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      appName,
    };
  },
  computed: {
    rankText() {
      const rankMap = ranksMapping[this.rank].label;
      return rankMap.replace('d', this.$t('段')).replace('k', this.$t('級'));
    },
  },
};
</script>

<style lang="scss" scoped>
.certificate-container {
  img {
    top: 0;
    width: 100%;
    height: 'auto';
    @media screen and (min-width: 768px) {
      width: 461px;
      left: 50%;
    }
  }
  .text-wrapper {
    top: 0;
    z-index: 1;
    .logo-title {
      height: 5vw;
      font-size: 3.75vw;
      margin-top: 15.9vw;
      @media screen and (min-width: 768px) {
        height: 24px;
        font-size: 18px;
        margin-top: 82px;
      }
    }
    .stage-title {
      height: 10.6vw;
      font-size: 6.25vw;
      color: #bc8954;
      margin-top: 3.75vw;
      @media screen and (min-width: 768px) {
        height: 55px;
        font-size: 32px;
        margin-top: 20px;
      }
    }
    .username {
      height: 10.6vw;
      font-size: 6.25vw;
      margin-top: 14.3vw;
      @media screen and (min-width: 768px) {
        height: 55px;
        font-size: 32px;
        margin-top: 73px;
      }
    }
    .content {
      height: 17.5vw;
      font-size: 5vw;
      margin-top: 10vw;
      @media screen and (min-width: 768px) {
        height: 90px;
        font-size: 24px;
        margin-top: 51px;
      }
    }
    .date {
      height: 6.25vw;
      font-size: 3.75vw;
      @media screen and (min-width: 768px) {
        height: 33px;
        font-size: 18px;
      }
    }
  }
}
</style>
